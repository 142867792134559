@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: "Valia";
  src:url("Valia-Truelight.ttf.woff");
}
* {
  margin: 0;
  padding: 0;
}
*::-webkit-scrollbar{
  width: 10px;
  background-color: #f5f5f5;
}
*::-webkit-scrollbar:horizontal{
  height: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #333333;
  background-image: -webkit-linear-gradient(45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
}
*::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #f5f5f5;
}
body {
  background-color: #5a5a5a;
  min-height: 100vh;
  position: relative;
}
.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  display: grid;
  grid-template-columns: 3% 97%;
  transition: grid-template-columns linear 0.5s;
}

.App.menuActive {
  grid-template-columns: 12.5% 87.5%;
}

._2kgoq {
  display: none;
}
._4qjuR {background-color: rgb(0,255,0) !important;}
._Ceyyy {background-color: rgb(255, 166, 0) !important;}
._20BJ- {background-color: rgb(219,0,0) !important;}

.green-font {color: rgb(0, 255, 0);}
.red-font {color: rgb(219, 0, 0);}

.grid-2y {grid-row: span 2;}
.grid-2x {grid-column: span 2;}

/* checkbox */
input:not([type="checkbox"]) {
  background-color: #969696;
  border:none;
  border: solid 2px #ffffff;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 2px 15px;
  transition: all 0.5s;
}

input:not([type="checkbox"]):focus {
  background-color: transparent;
  border:none;
  outline: none;
  border: solid 2px #333333;
  color:#ffffff; 
}
input[type="checkbox"] {
  appearance: none;
  display: block;
  height:20px;
  width: 20px;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: #333333;
  border: 2px solid #333333;
  border-radius: 2px;
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.95em;
  height: 0.95em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: rgb(28, 96, 28);
}
input[type="checkbox"]:checked {
  background-color: rgb(47, 161, 47);
  border-color: rgb(28, 96, 28);
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
/* end checkbox */

/* Selector */
.selector {
  width: fit-content;
  position: relative;
  color: black;
  height: 25px;
  overflow: hidden;
  z-index: 100;
  cursor: pointer;
  border: solid 3px #333333;
}
.selector.active {
  overflow: visible;
}
.selector h5 {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px;
  background-color: lightgray;
  text-align: center;
  user-select: none;
}
.selector h5:nth-child(even) {
  background-color: white;
}
.selector h5:hover {
  background-color: gray;
  color: white;
}
/* end Selector */

/* Login Form */
.login {
  height:100vh;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-column: 2 span;
}
.login .form {
  background-color: #ffffff;
  padding: 75px;
  border-radius: 10px;
  width: 400px;
}
.login .form h1 {
  font-size: 46px;
}
.login .form .logo {
  height: 250px;
}
.login .form .logo img {
  height: 100%;
}
.login .form .form-input {
  height:50px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.login .form .form-input input {
  width: 250px;
  height:30px;
  border: none;
  border-bottom: solid 3px #8d8d8d;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 14px;
  color: #333333;
  border-radius: 0;
}
.login .form .form-input input:focus-visible {
  outline: none;
}
.login .form button {
  margin: 15px 0;
  width: 250px;
  height:40px;
  border-radius: 50px;
  border: none;
  background-color: #8d8d8d;
  color:white;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.login .form p {
  margin-top: 50px;
  font-size: 12px;
}
.login .form p a {
  color:blue;
}
/* End Login Form */

/* Menu */
.menu {
  position: relative;
  background-color: #333333;
  width: 100%;
  height:100vh;
  overflow: hidden;
  box-sizing: border-box;
  padding: 2.5vh 5%;
  color: #ffffff;
}
.menu .header {
  height:10%;
  width: 100%;
  position: relative;
}
.menu .header img {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 50%;
  transition: height linear 0.5s;
}
.App.menuActive .menu .header img {
  height:100%;
}
.menu .nav {
  position: relative;
  margin: 2.3vh 0;
  height:75%;
  border: solid 1px #ffffff;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}
.App.menuActive .menu .nav .nav-object {
  grid-template-columns: 10% 73%;
}
.menu .nav .nav-object {
  display: grid;
  height:30px;
  width: 100%;
  overflow: hidden;
  margin: 8px 10%;
  grid-template-columns: 100% 0%;
  align-items: center;
  column-gap: 7%;
  box-sizing: border-box;
  color:#ffffff;
  text-decoration: none;
  transition: all linear 0.5s, grid-template-columns linear 0.5s;
}
.menu .nav .nav-object:hover, .menu .footer h5:hover {
  color: rgb(255, 251, 0);
  text-decoration: underline;
}
.menu .nav .nav-object.active {
  color:orange;
  text-decoration: underline;
  transition:grid-template-columns linear 0.5s;
}
.menu .nav .nav-object .nav-icon,.menu .nav .nav-object .nav-title {
  transition: justify-self linear 0.5s;
}
.App.menuActive .menu .nav .nav-object .nav-icon {
  font-size: 20px;
  justify-self: center;
}
.App.menuActive .menu .nav .nav-object .nav-title {
  justify-self: start;
}
.menu .footer {
  height:10%;
  line-height: 40px;
}
.menu .footer h2 {
  width: 0%;
  overflow: hidden;
  transition: width linear 0.5s;
}
.App.menuActive .menu .footer h2 {
  width: 100%;
}
.menu .footer h5:hover {
  text-decoration: underline;
  cursor: pointer;
}
/* End Menu */

/* Page */
.page {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 60px 30px 50px;
  position: relative;
}
.page .page-action {
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  margin-bottom: 20px;
  border-bottom: solid 1px #ffffff;
  text-align: end;
  min-height:30px;
}
.page .list {
  overflow: hidden;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 50px;
  box-sizing: border-box;
  row-gap: 50px;
}
.page .list-clipboard {
  overflow: hidden;
  width: 100%;
  display: grid;
  column-gap: 20px;
  box-sizing: border-box;
  row-gap: 20px;
}
.page .list .list-entity {
  width: 100%;
  overflow: hidden;
  height:auto;
  background-color: #969696;
  box-sizing: border-box;
  padding: 25px 25px 25px 25px;
  border-radius: 15px;
}
.page .list-clipboard .list-title,.page .list-clipboard .list-entity {
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: #969696;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 100% 0;
  border-bottom: #ffffff 2px solid;
  grid-template-columns: auto;
}
.page .list-clipboard .list-title { background-color: transparent;}
.page .list-clipboard .list-entity:last-child {
  border-bottom: none;
}
.page .list-clipboard .list-entity .list-entity-field {
  display: block;
  width: 100%;
}
.list-entity-field {
  height:26px;
  margin-bottom: 5px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
}
.list-entity-field h4 {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 100%;
}
.list-entity-field-boolean h4 {
  display: grid;
  justify-content: center;
  align-items: center;
}
.list-entity-field-boolean h4 input[type="checkbox"] {
  appearance: none;
  display: block;
  height:20px;
  width: 20px;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: #333333;
  border: 2px solid #333333;
  border-radius: 2px;
  display: grid;
  place-content: center;
}
.list-entity-field-boolean h4 input[type="checkbox"]::before {
  content: "";
  width: 0.95em;
  height: 0.95em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: rgb(28, 96, 28);
}
.list-entity-field-boolean h4 input[type="checkbox"]:checked {
  background-color: rgb(47, 161, 47);
  border-color: rgb(28, 96, 28);
}
.list-entity-field-boolean h4 input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.edit-button,.delete-button,.cancel-button {
  background-color: rgb(47, 161, 47);
  color: #ffffff;
  padding: 8px;
  border: solid 2px rgb(47, 161, 47);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  margin-right: 15px;
  transition: all linear 0.5s;
}
.small-button {
  font-size: 12px;
  padding: 4px;
}
.delete-button {
  background-color: rgb(219,0,0);
  border-color: rgb(219,0,0);
}
.cancel-button {
  background-color: orange;
  border-color: orange;
}
.add-button {
  background-color: rgb(47, 161, 47);
  color: #ffffff;
  padding: 10px;
  border: solid 2px rgb(47, 161, 47);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 25px;
  transition: all linear 0.5s;
}
.add-button:hover,
.edit-button:hover,
.delete-button:hover,
.cancel-button:hover {
  border: solid 2px #ffffff;
}
.disabled-button {
  background-color: #5a5a5a;
  color: #969696;
}
.button {
  cursor: pointer;
}
/* End Page */

/* Currency,Country,Language */
.currency .list-clipboard {
  grid-template-columns: repeat(4,1fr);
}
.country .list-clipboard {
  grid-template-columns: repeat(2,1fr);
}
.language .list-clipboard {
  grid-template-columns: repeat(3,1fr);
}
.currency .list-clipboard .list-entity,.country .list-clipboard .list-entity,.language .list-clipboard .list-entity {
  align-items: center;
  min-height:50px;
  border-bottom: none;
}
.currency .list-clipboard .list-entity {
  grid-template-columns: 50% 50%;
}
.country .list-clipboard .list-entity,.language .list-clipboard .list-entity {
  grid-template-columns: 75% 25%;
}
.currency .page-action .search,.country .page-action .search,.language .page-action .search {
  color:white;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 10px;
  width: 30%;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: 10px;
}
.currency .page-action .search input,.country .page-action .search input,.language .page-action .search input {
  background-color: #969696;
  border:none;
  border: solid 2px #ffffff;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 2px 15px;
  transition: all 0.5s;
}
.currency .page-action .search input:focus,.country .page-action .search input:focus,.language .page-action .search input:focus {
  background-color: transparent;
  border:none;
  border: solid 2px #969696;
  color:#ffffff; 
}
/* End Currency,Country,Language */

/* Role */
.role .role-form {
 color: white;
}
.role .role-form h2 {
  border-bottom: solid 2px #ffffff;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.role .role-form .input > * {
  display: inline-block;
  margin-bottom: 15px;
}
.role .role-form .input h4 {
  width: 20%;
  text-align: end;
}
.role .role-form .input input {
  width: 40%;
  margin-left: 8px;
  color: #ffffff;
  background-color: rgba(90, 90, 90, 1);
}
.role .role-form .access {
  background-color: rgba(90, 90, 90, 1);
  border: solid 2px #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}
.role .role-form .access h4 { 
  border-bottom: solid 2px #ffffff;
  padding: 5px 0;
}
.role .role-form .list {
  grid-template-columns: repeat(4,1fr);
  box-sizing: border-box;
  background-color: #ffffff;
  row-gap: 2px;
  column-gap: 2px;
}
.role .role-form .list h5{
  display: grid;
  grid-template-columns: 75% 25%;
  place-content: center;
  height:40px;
  background-color: rgba(90, 90, 90, 1);
}
.role .role-form .submit {
  display: grid;
  grid-template-columns: 40% 40%;
  justify-content: center;
  column-gap: 50px;
}
.role .role-form .submit button {
  margin: 15px 0;
  /* width: 250px; */
  height:40px;
  border-radius: 50px;
  background-color: #5a5a5a;
  color:white;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  border:solid 1px #5a5a5a;
  transition: all linear 0.5s;
}
.role .role-form .submit button:hover {
  border-color: #ffffff;
}
.role .role-form .submit .success {
  background-color: rgb(47, 161, 47);
  border-color: rgb(47, 161, 47);
}
.role .role-form .submit .cancel {
  background-color: rgb(255, 166, 0);
  border-color: rgb(255, 166, 0);
}
.role .role-form .submit .delete {
  background-color: rgb(219,0,0);
  border-color: rgb(219, 0, 0);
}
.role .list-clipboard {
  row-gap: 0;
  background-color: #333333;
  border: solid 2px #ffffff;
}
.role .list-clipboard .list-title,
.role .list-clipboard .list-entity {
  grid-template-columns: 2fr repeat(4,1fr);
  grid-template-rows: 100%;
  align-items: center;
  padding: 8px 0;
  color: #ffffff;
}
/* End Role */

/* Users */
.users .list-clipboard {
  row-gap: 0;
  background-color: #333333;
  border: solid 2px #ffffff;
}
.users .list-clipboard .list-title,
.users .list-clipboard .list-entity{
  grid-template-columns: repeat(3,2fr) repeat(2,1fr) 1.5fr;
  grid-template-rows: 100%;
  align-items: center;
  padding: 8px 0;
  color: #ffffff;
}
.users .list-clipboard .font-icon {
  font-size: 20px;
}
.users .user-view .popup-window-nav {
  grid-template-columns: repeat(3,1fr);
  background-color: #ffffff;
  column-gap: 2px;
}
.users .user-edit .popup-window-nav {
  grid-template-columns: repeat(2,1fr);
  background-color: #ffffff;
  column-gap: 2px;
}
.users .popup-window-panel .panel-grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  align-items: center;
  justify-content: center;
  row-gap: 2px;
  column-gap: 2px;
  background-color: #ffffff;
  padding: 2px;
}
.users .popup-window-panel .panel-grid .block {
  background-color:#333333;
  height: 100%;
}
.users .popup-window-panel .info-grid {
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: 100px 100px;
}
.users .popup-window-panel .info-grid .block {
  display: grid;
  align-items: center;
  align-content: center;
  grid-template-rows: 25% 25%;
}
.users .popup-window-panel .orders-grid,
.users .popup-window-panel .supports-grid {
  grid-template-columns: 1fr;
  grid-template-rows: 40px;
  grid-auto-rows: 30px;
}
.users .popup-window-panel .orders-grid .block,
.users .popup-window-panel .supports-grid .block {
  display: grid;
  grid-template-columns: repeat(4,1fr) 0.5fr;
  grid-template-rows: 1fr;
  column-gap: 1px;
  background-color: #7e7e7e;
}
.users .popup-window-panel .orders-grid .block.title .line-block,
.users .popup-window-panel .supports-grid .block.title .line-block {
  font-size: 20px;
}
.users .popup-window-panel .orders-grid .block .line-block,
.users .popup-window-panel .supports-grid .block .line-block {
  background-color:#333333;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.users .popup-window-panel .edit-grid {
  grid-template-columns: repeat(2,1fr);
  grid-auto-rows: 100px;
}
.users .popup-window-panel .edit-grid .block {
  display: grid;
  align-items: center;
  align-content: center;
  grid-template-rows: 25% 25%;
}
.users .popup-window-panel .action-grid {
  display: grid;
  grid-template-columns: 25% 25%;
  align-items: center;
  justify-content: center;
  margin: 15px;
}
/* End Users */

/* Category */
.categories .list-clipboard {
  row-gap: 0;
  background-color: #333333;
  border: solid 2px #ffffff;
  grid-template-rows: auto;
  grid-auto-rows: 175px;
}
.categories .list-clipboard .list-title,
.categories .list-clipboard .list-entity{
  grid-template-columns: repeat(1,2fr) repeat(3,1fr);
  grid-template-rows: 100%;
  align-items: center;
  padding: 8px 0;
  color: #ffffff;
}
.categories .list-clipboard .list-entity .spinner-container {
  grid-column: 4 span;
}
.categories .list-clipboard .font-icon {
  font-size: 20px;
}
.categories .list-clipboard .picture {
  height: 150px;
}
.categories .picture_default {
  font-size: 150px;
}
.categories .category-new .list,
.categories .category-edit .list {
  grid-template-columns: repeat(1,1fr);
  box-sizing: border-box;
  background-color: #ffffff;
  row-gap: 1px;
  column-gap: 2px;
  border-top: solid 1px white;
}
.categories .category-new .list h5,
.categories .category-edit .list h5{
  display: grid;
  grid-template-columns: 25% 75%;
  place-content: center;
  height:40px;
  background-color: rgba(90, 90, 90, 1);
  padding: 0 10px;
}
.categories .category-new .block,
.categories .category-edit .block {
  margin-bottom: 15px;
  border: solid 2px white;
  border-radius: 5px;
  background-color: #333333;
}
.categories .category-new .container,
.categories .category-edit .container {
  height: 300px;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
  background-color: rgba(90, 90, 90, 1);
  border-top: solid 1px white;
}
.categories .category-new .container .half,
.categories .category-edit .container .half {
  overflow: hidden;
}
.categories .category-new .container .half .picture,
.categories .category-edit .container .half .picture {
  height: 250px;
}

/* End Category */

/* Products */
.products .list-clipboard {
  row-gap: 0;
  background-color: #333333;
  border: solid 2px #ffffff;
  grid-template-rows: auto;
  grid-auto-rows: 175px;
}
.products .list-clipboard .list-title,
.products .list-clipboard .list-entity {
  grid-template-columns: repeat(1,2fr) repeat(6,1fr);
  grid-template-rows: 100%;
  align-items: center;
  padding: 8px 0;
  color: #ffffff;
}
.products .list-clipboard .picture {
  height: 150px;
}
.products .picture_default {
  font-size: 150px;
}
.products .page-action .page-action-nav {
  /* height:10px; */
  display: grid;
  grid-template-columns: repeat(4,7fr) repeat(3,1fr);
  width: 100%;
  text-align: center;
  color: white;
}
.products .page-action .page-action-nav .page-action-nav-title {
  cursor: pointer;
  transition: all linear 0.5s;
}
.products .page-action .page-action-nav .page-action-nav-title.active {
  color:orange;
  text-decoration: underline;
}
.products .page-content {
  color: white;
}
.products .page-content .infos {
  display: grid;
  grid-template-columns: 1fr 3fr;
  background-color: white;
}
.products .page-content .content-block {
  background-color: #5a5a5a;
  padding: 15px 10px;
  display: grid;
  grid-template-columns: 100%;
  align-content: center;
  box-sizing: border-box;
}
.products .page-content .infos .side-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(2,1fr);
  background-color: white;
  padding: 1px;
  row-gap: 1px;
  column-gap: 1px;
}
.products .page-content .infos .main-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2,1fr);
  background-color: white;
  padding: 1px;
  row-gap: 1px;
  column-gap: 1px;
}
.products .page-content .list-clipboard {
  grid-auto-rows: auto;
}
.products .page-content .list-clipboard div {
  display: grid;
  justify-content: center;
  align-content: center;
  min-height: 50px;
  background-color: #787878;
}
.products .page-content .list-clipboard>div:nth-child(even) {
  background-color: #8d8d8d;
}
.options > h3:first-of-type,
.options > span > h3:first-of-type,
.stocks > h3:first-of-type,
.pictures > h3:first-of-type {
  width: fit-content;
  padding: 20px 50px;
  margin: 0 auto;
  border: solid 2px white;
}
.options .options-list,
.stocks .options-list,
.pictures .options-list {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  row-gap: 25px;
  column-gap: 25px;
  padding-top: 30px;
}
.options .options-list .option-type,
.stocks .options-list .option-type,
.pictures .options-list .option-type {
  border: solid 2px white;
  border-radius: 20px;
  padding: 20px;
  background-color: #787878;
  transition: background-color linear 0.5s;
}
.options .options-list .option-type:hover,
.stocks .options-list .option-type:hover,
.pictures .options-list .option-type:hover {
  background-color: #8d8d8d;
}
.options .options-list .option-type > h4 {
  float: left;
  width: 50%;
  margin: 10px 0;
  font-size: smaller;
}
.options .option-type .option-available-details .option-display-color {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: right;
  align-items: center;
}
.options .option-type .option-available-details .option-display-color span {
  display: block;
  height:20px;
  width: 20px;
  margin-left: 5px;
}
.pictures .options-list .option-type .picture {
  width: 100%;
}
.products .form .selector {
  margin: 0 auto;
}
.products .form .input-grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 80% 20%;
}
.products .form input:not([type="checkbox"]):focus {
  background-color: lightgray;
  color: black;
}
.products .form .clipboard-input {
  display: grid;
  grid-template-columns: 1fr 3fr;
  box-sizing: border-box;
  padding: 10px 15px;
}
.products .form .form-action {
  box-sizing: border-box;
  padding: 10px 0;
}
.products .form .form-action .button {
  width: 100%;
}
.products .page-action-list {
  display: grid;
  grid-template-columns: 10% 5%;
  justify-content: end;
  align-items: center;
  justify-items: center;
}
.products .form .options>span {
  display: grid;
  grid-template-columns: 90% 10%;
}
.products .form .options>span .add-button {
  font-size: 35px;
}
.products .form .options .option-available > span {
  display: block;
  text-align: end;
  margin: 5px;
}
.products .form .options .option-available > span .add-button {
  padding: 4px;
  font-size: 15px;
}
.products .form .options .option-available .option-available-details {
  padding: 7px;
  justify-content:initial;
}
.products .form .options .option-available .option-available-details .option-display-color {
  grid-template-columns: 20% 50% 20% 10%;
  padding: 4px 2px;
  gap: 2px;
}
.products .form .options .option-available .option-available-details .option-action {
  display: block;
  text-align: end;
  padding: 5px 2px;
  width: 100%;
}
.products .form .options .option-available .option-available-details .option-action .delete-button {
  font-size: 12px;
  padding: 4px;
}
.products .form .options .option-available .option-type-action {
  width: 100%;
  text-align: end;
  padding: 5px 2px;
}
.products .form .options .option-available .option-type-action .delete-button {
  font-size: 15px;
  padding: 4px;
}
.products .form .stocks .options-list .option-type h4 {
  display: grid;
  grid-template-columns: 55% 25% 20%;
}
.products .popup-window-panel .picture-preview {
  width: 100%;
  text-align: center;
}
.products .popup-window-panel .picture-preview img {
  max-width: 100%;
}
.products .form .pictures .options-list .option-type img {
  pointer-events: none;
}
.products .form .pictures .options-list .option-type {
  cursor: pointer;
  position: relative;
  padding-bottom: 60px;
}
.products .form .pictures .options-list .option-type .option-type-action {
  position: absolute;
  bottom: 20px;
  right: 0px;
  width: 100%;
  text-align: end;
}
/* End Products */

/* Orders */
.supports .list-clipboard,
.orders .list-clipboard {
  row-gap: 0;
  background-color: #333333;
  border: solid 2px #ffffff;
  grid-template-rows: auto;
  grid-auto-rows: 75px;
}
.supports .list-clipboard .list-title,
.supports .list-clipboard .list-entity,
.orders .list-clipboard .list-title,
.orders .list-clipboard .list-entity {
  grid-template-columns: repeat(7,1fr);
  grid-template-rows: 100%;
  align-items: center;
  padding: 8px 0;
  color: #ffffff;
}
.supports .page-action .search,
.orders .page-action .search {
  color:white;
  display: grid;
  grid-template-columns: 60% 32% 8%;
  grid-template-rows: 100%;
  width: 30%;
  height: 30px;
  margin: 0 0 0 auto;
  background-color: #969696;
  border:none;
  border: solid 2px #ffffff;
  border-radius: 30px;
  box-sizing: border-box;
  /* overflow: hidden; */
}
.supports .page-action .search input ,
.orders .page-action .search input {
  background-color: transparent;
  border-radius: 30px 0 0 30px;
  box-sizing: border-box;
  padding: 2px 15px;
  transition: all 0.5s;
}
.supports .page-action .search input:focus ,
.orders .page-action .search input:focus {
  background-color: #5a5a5a;
  border:none;
  border: solid 2px #5a5a5a;
  color:#ffffff; 
}
.supports .page-action .search .selector,
.orders .page-action .search .selector{
  border: none;
  width: 100%;
  height: 100%;
}
.supports .page-action .search .selector h5 ,
.orders .page-action .search .selector h5 {
  height: 100%;
  
}
.supports .page-action .search .selector h5:first-child ,
.orders .page-action .search .selector h5:first-child {
  background-color: white;
}
.supports .page-action .search .selector.active h5:first-child ,
.orders .page-action .search .selector.active h5:first-child {
  background-color: lightgray;
}
.supports .page-action .search .selector h5:nth-child(even) ,
.orders .page-action .search .selector h5:nth-child(even) {
  background-color: white;
}
.supports .page-action .search .selector h5:hover,
.orders .page-action .search .selector h5:hover {
  background-color: gray;
  color: white;
}
.supports .page-action .search .button,
.orders .page-action .search .button {
  border: none;
  border-radius: 0 30px 30px 0;
  background: white;
  font-size: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.orders .order-grid {
  display: grid;
  width: 100%;
  height:89%;
  background-color: #333333;
  border: solid 2px #ffffff;
  grid-template-columns: 75% 20%;
  grid-template-rows: 95%;
  justify-content: space-around;
  align-content: space-around;
  column-gap: 25px;
  color: white;
}
.popup-window .order-grid {
  grid-template-columns: 55% 40%;
  height: 70vh;
}
.orders .order-grid>div { 
  background-color: #969696;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  display: grid;
}
.orders .order-grid .order-cart {
  grid-template-columns: 100%;
  grid-template-rows: 8% 90%;
  justify-content: center;
  align-items: center;
  align-content: space-around;
}
.popup-window .order-grid .order-cart {
  grid-template-rows: repeat(4,1fr);
}
.orders .order-grid .order-cart .order-cart-title {
  height: 100%;
  text-align: center;
  border-bottom: solid #333333 5px;
  display: grid;
  justify-content: center;
  align-content: center;
}
.orders .order-grid .order-cart .order-cart-grid {
  height: 100%;
  align-items: start;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: 160px;
  row-gap: 5px;
}
.orders .order-grid .order-cart .order-cart-grid .cart-product {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: repeat(6,1fr);
  background-color: #5a5a5a;
  border: solid 2px white;
  border-width: 2px 0;
  padding: 5px;
  height: 150px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.orders .order-grid .order-cart .order-cart-grid .cart-product > div {
  width: 100%;
  height: fit-content;
}
.orders .order-grid .order-cart .order-cart-grid .cart-product .spinner-container {
  grid-column: span 6;
}
.orders .order-grid .order-cart .order-cart-grid .cart-product .cart-product-picture {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.orders .order-grid .order-cart .order-cart-grid .cart-product .picture {
  position: absolute;
  top:-5px;
  left:50%;
  transform: translateX(-50%);
  height: 150px;
}
.orders .order-grid .order-cart .order-cart-grid .cart-product .picture_default {
  font-size: 150px;
  position: absolute;
  top:-5px;
  left:50%;
  transform: translateX(-50%);
  height: 150px;
}
.orders .order-grid .order-info {
  grid-template-columns: 100%;
  grid-template-rows: 10% 65% 25%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow-x: hidden;
}
.orders .order-grid .order-info h2,
.orders .order-grid .order-info h3 {
  text-decoration: underline;
}
.orders .order-grid .order-info .order-info-user {
  border-top: solid #333333 10px;
  padding: 10px 0 0 0;
  box-sizing: border-box;
  height: 100%;
}
.orders .order-grid .order-info .order-info-general {
  box-sizing: border-box;
  height: 100%;
}
.orders .order-grid .order-info .order-info-grid{
  display: grid;
  grid-template-columns: 30% 65%;
  justify-content: space-around;
  height: 100%;
  align-content: space-evenly;
}
.orders .order-grid .order-info .order-info-grid h4{
  justify-self: end;
  align-content: center;
}
.orders .order-grid .order-info .order-info-grid h5{
  justify-self: start;
  overflow-x: auto;
  max-width: 100%;
  align-content: center;
}
/* End Orders */

/* Support */
.supports .support-grid {
  display: grid;
  width: 100%;
  height:87%;
  background-color: #333333;
  border: solid 2px #ffffff;
  grid-template-columns: 75% 20%;
  grid-template-rows: 78% 20%;
  justify-content: space-around;
  align-content: space-between;
  column-gap: 25px;
  color: white;
  padding: 11px 0;
}
.supports .support-grid>div { 
  background-color: #969696;
  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
}
.supports .support-grid .chat {
  height:100%;
}
.supports .support-grid .chat-container {
  align-content: start;
  transform: scale(-1);
}
.supports .support-grid .chat .message {
  transform: scale(-1);
  width: fit-content;
  padding: 15px;
  background-color: #7e7e7e;
}
.supports .support-grid .chat .owner-message {
  margin: 25px 50px 25px auto;
}
.supports .support-grid .chat .owner-message:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 10px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 10px solid;
	border-color: #7e7e7e transparent transparent #7e7e7e;
}
.supports .support-grid .chat .team-message {
  margin: 25px auto 25px 50px;
  background-color: #88cc00;
}
.supports .support-grid .chat .team-message:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: 10px;
	bottom: -20px;
	border: 10px solid;
	border-color: #88cc00 #88cc00 transparent transparent;
}
.supports .support-grid .info{
  display: grid;
  grid-template-columns: 30% 65%;
  row-gap: 10px;
  justify-content: space-around;
  align-content: space-evenly;
  height: max-content;
  padding: 20px;
}
.supports .support-grid .info h4{
  justify-self: end;
  align-content: center;
}
.supports .support-grid .info h5{
  justify-self: start;
  overflow-x: auto;
  max-width: 100%;
  align-content: center;
}
.supports .support-grid .textbox {
  background-color: #5a5a5a;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 90% 10%;
  grid-template-rows: 100%;
}
.supports .support-grid .textbox textarea {
  border: none;
  border-radius: 0;
  background-color: #8d8d8d;
  overflow-x: hidden;
  overflow-y: auto;
  resize: none;
  padding: 10px 25px;
  color: white;
  font-size: medium;
  text-align: center;
}
.supports .support-grid .textbox textarea:hover,
.supports .support-grid .textbox textarea:focus {
  background-color: #969696;
  outline: none;
}
.supports .support-grid .textbox button {
  border: none;
  background-color: #5a5a5a;
  color: white;
  font-size: 56px;
  cursor: pointer;
}
/* End Support */

/* Dashboard */
.dashboard {
  display: grid;
  grid-template-columns: repeat(7,1fr);
  grid-template-rows: repeat(4,1fr);
  column-gap: 25px;
  row-gap: 25px;
  padding: 30px 25px;
  color: white;
}
.section {
  overflow: hidden;
  background-color: #969696;
  box-sizing: border-box;
  padding: 25px;
  border-radius: 15px;
}
.section-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}
.s2x {
  grid-column: span 2;
}
.s3x {
  grid-column: span 3;
}
.s5x {
  grid-column: span 5;
}
.s2y {
  grid-row: span 2;
}
.s3y {
  grid-row: span 3;
}
.action-section {
  width: 100%;
  height:50px;
  background-color: #787878;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 15px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.action-section button {
  font-family: inherit;
  font-size: inherit;
  font-weight: bolder;
  color: inherit;
  background-color: transparent;
  border:none;
  border-radius: 15px;
  border: solid 3px white;
  padding: 5px 15px;
  background-color: orange;
}
.error-section {
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: 20% 80%;
  width: 100%;
  height: 100%;
}
.error-section svg {
  font-size: 65px;
  justify-self: end;
  align-items: center;
}
.error-section{
  justify-self: start;
  align-items: center;
}
.error-none {
  background-color: #88cc00;
  color: #639400;
}
.error-available {
  background-color: #db0000;
  color: #a50000;
}
.dashboard .last-orders h2:first-of-type {
  margin-bottom: 15px;
}
.dashboard .dash-order {
  display: grid;
  width: 100%;
  height: 75px;
  overflow: hidden;
  grid-template-columns: 20% 32.5% 32.5% 15%;
  grid-template-rows: 100%;
  background-color: #5a5a5a;
  margin-bottom: 10px;
  border-radius: 10px;
}
.dashboard .dash-order > div {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
}
.dashboard .dash-order > a {
  display: grid;
  align-items: center;
  grid-template-columns: auto;
  justify-content: space-around;
}
.dashboard .dash-order .dash-order-country img{
  width: 100%;
  height: 100%;
}
.dashboard .dash-graph {
  color: black;
  height: 90%;
}
.dashboard .dash-graph *[aria-label] {}
/* End Dashboard */

/* Footer */
.footer-copy {
  /* background: rgba(40,40,40,0.8);
  background: linear-gradient(0deg, rgba(40,40,40,0.8) 0%, rgba(40,40,40,0.45) 65%, rgba(40,40,40,0) 100%); */
  position: absolute;
  height:3vh;
  width: 100%;
  bottom: 0;
}
.footer-copy-right {
  width: 100%;
  position: absolute;
  bottom: 0.2vh;
  font-size: 1vh;
  color: rgba(177, 177, 177, 0.5);
}
.footer-copy-right a {
  color: rgba(177, 177, 177, 0.5);
}
/* End Footer */

/* Loading */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* End Loading*/

/* Page 404 */
.e404 {
  color:white;
  height:100vh;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 100%;
  grid-template-rows: 45% 80px 100px 50px;
}
.e404 svg {
  height:60%;
  align-self: self-end;
}
.e404 h1 {
  font-size: 42px;
  align-self: self-end;
}
.e404 p {
  width: 1000px;
  text-align: center;
  font-size: 20px;
}
.e404 a {
  color:aquamarine;
  font-size: 18px;
}
/* End Page 404*/

/* PopUpWindow */
.popup-background {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(90, 90, 90, 0.90);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
}
.popup-close {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 40px;
  color: white;
}
.popup-window {
  position: relative;
  background-color: rgba(150, 150, 150,1);
  padding: 20px 50px;
  border-radius: 20px;
  box-sizing: border-box;
  min-width: 60%;
  max-width: 80%;
  max-height: 80%;
  align-self: center;
  justify-self: center;
  color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
}
.popup-window-nav {
  display: grid;
  border: #ffffff solid 2px;
}
.popup-window-nav-title {
  padding: 10px;
  background-color: #333333;
  transition: all linear 0.5s;
  cursor: pointer;
}
.popup-window-nav-title.active {
  background-color: orange;
}
.popup-window-panel {
  width: 100%;
  min-height:300px;
  box-sizing: border-box;
  padding: 10px;
}
.popup-window-panel .spinner-container {
  min-height: 300px;
}

/* End PopUpWindow */
